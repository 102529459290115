import { LOGOUT } from '../auth';

function withAuthCheck(func, dispatch) {
  async function authCheckInner(...args) {
    try {
      return await func.apply(null, args);
    } catch (ex) {
      if (ex?.response?.status === 401) {
        dispatch({type: LOGOUT});
      }
      throw ex;
    }
  }

  return authCheckInner;
}

export default withAuthCheck;
