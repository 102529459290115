import {Container, InputGroup, Form, FormControl, Button, Row} from 'react-bootstrap';
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { updateProfile, getUser } from '../../store/actions/users';

import './ProfileEdit.scss';
import Meta from '../../components/Meta';
import Header from '../../components/Header';

const ProfileEdit = () => {
  const pageTitle = 'Edit My Profile';

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const onProfilePicChange = (e, setFieldValue) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setFieldValue('profilePic', e.target.files[0]);
  };

  const onSubmitHandler = async (values, { setSubmitting }) => {
    const userId = auth.user.userId;

    setSubmitting(true);
    if (auth.user.role === 'ADMIN') {
      await dispatch(updateProfile({...values, userId}));
    } else {
      delete values.email;
      await dispatch(updateProfile({...values, userId}));
    }
    setSubmitting(false);
  };

  useEffect(() => {
    dispatch(getUser(auth.user));
  }, [auth.user, dispatch]);

  const validateForm = values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }
    return errors;
  };

  return (
    <Container className="profile-edit-container">
      <Meta title={pageTitle}/>
      <Header head={pageTitle} />
      <Formik
        initialValues={{
          email: auth.user?.email,
          firstName: auth.user?.firstName,
          lastName: auth.user?.lastName,
          gender: auth.user?.gender,
          dateOfBirth: moment(auth.user?.dateOfBirth).format("YYYY-MM-DD"),
          profilePic: ""
        }}
        validate={validateForm}
        onSubmit={onSubmitHandler}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleReset,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => <>
          <InputGroup className="mb-3" hasValidation>
            <InputGroup.Text>First Name</InputGroup.Text>
            <FormControl
              type="name"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={errors.firstName && touched.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName && touched.firstName && errors.firstName}
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-3" hasValidation>
            <InputGroup.Text>Last Name</InputGroup.Text>
            <FormControl
              type="name"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={errors.lastName && touched.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName && touched.lastName && errors.lastName}
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-3" hasValidation>
            <InputGroup.Text>Email</InputGroup.Text>
            <FormControl
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={errors.email && touched.email}
              readOnly={auth.user.role !== 'ADMIN'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email && touched.email && errors.email}
            </Form.Control.Feedback>
          </InputGroup>
          <InputGroup className="mb-3" hasValidation>
            <InputGroup.Text>Gender</InputGroup.Text>
            <FormControl
              type="text"
              name="gender"
              value={values.gender}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={errors.gender && touched.gender}
            />
            <Form.Control.Feedback type="invalid">
              {errors.gender && touched.gender && errors.gender}
            </Form.Control.Feedback>
          </InputGroup>
          <InputGroup className="mb-3" hasValidation>
            <InputGroup.Text>Date of Birth</InputGroup.Text>
            <FormControl
              type="date"
              name="dateOfBirth"
              value={values.dateOfBirth}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={errors.dateOfBirth && touched.dateOfBirth}
            />
            <Form.Control.Feedback type="invalid">
              {errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-3 profile-picture" hasValidation>
            <InputGroup.Text>Profile Pic</InputGroup.Text>
            <FormControl
              type="file"
              name="profilePic"
              onChange={e => onProfilePicChange(e, setFieldValue)}
              onBlur={handleBlur}
              isInvalid={errors.profilePic && touched.profilePic}
            />
            <Form.Control.Feedback type="invalid">
              {errors.profilePic && touched.profilePic && errors.profilePic}
            </Form.Control.Feedback>
          </InputGroup>
          <div className="profile-btns">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </>
        }
      </Formik>
      <Row className="mb-5" />
      <Row className="mb-5" />
    </Container>
  )
};

export default ProfileEdit;
