import { combineReducers } from 'redux';
import auth from './auth';
import users from './users';
import paychecks from './paychecks';
import motd from './motd';

export default combineReducers({
  auth,
  users,
  paychecks,
  motd,
});
