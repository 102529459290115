import MotdService from '../../services/MotdService';
import withAuthCheck from '../actions/utils/withAuthCheck';

export const MOTD_GET_START = 'MOTD_GET_START';
export const MOTD_GET_SUCCESS = 'MOTD_GET_SUCCESS';
export const MOTD_GET_ERROR = 'MOTD_GET_ERROR';

export const getMessageOfTheDay = () =>
  async (dispatch) => {
    dispatch({
      type: MOTD_GET_START
    });

    try {
      const response = await withAuthCheck(MotdService.getMessageOfTheDay, dispatch)();
      const { message } = response.data;
      dispatch({
        type: MOTD_GET_SUCCESS,
        message
      });
    } catch (ex) {
      dispatch({
        type: MOTD_GET_ERROR
      });
    }
  };
