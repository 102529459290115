import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './components/ProtectedRoute';

// pages
import Home from './pages/Home/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import Layout from './layout/Layout';
import PayHistory from './pages/PayHistory';
import ManageEmployees from './pages/ManageEmployees';
import EmployeeDetails from './pages/EmployeeDetails';
import ProfileEdit from './pages/profile/ProfileEdit';
import BioEdit from './pages/profile/BioEdit';
import PasswordChange from "./pages/profile/PasswordChange";
import CompanyDirectory from "./pages/CompanyDirectory";

import { tokenLogin } from './store/actions/auth';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tokenLogin());
  }, [dispatch]);

  return (
    <Layout>
      <Switch>
        <Route path='/' component={Home} exact />
        <ProtectedRoute role="USER" path='/dashboard' component={Dashboard} exact />
        <ProtectedRoute role="USER" path='/pay-history' component={PayHistory} exact />
        <ProtectedRoute role="ADMIN" path='/admin/dashboard' component={Dashboard} exact />
        <ProtectedRoute role="ADMIN" path='/admin/manage-employees' component={ManageEmployees} exact />
        <ProtectedRoute role="ADMIN" path='/admin/manage-employees/:id' component={EmployeeDetails} exact />
        <ProtectedRoute role="USER" path='/profile-edit' component={ProfileEdit} exact />
        <ProtectedRoute role="USER" path='/bio-edit' component={BioEdit} exact />
        <ProtectedRoute role="USER" path='/password-change' component={PasswordChange} exact />
        <ProtectedRoute role="USER" path='/directory' component={CompanyDirectory} exact />
        <Route path='/about' component={About} />
        <Route component={NotFound} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default App;
