import React, { useState, useEffect } from 'react';
import { Alert, Button, FormControl, Form, InputGroup, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';

import { login } from '../../store/actions/auth';

function LoginModal(props) {
  const { show, onClose } = props;
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);

  const handleClose = () => {
    setIsError(false);
    onClose();
  };

  useEffect(() => {
    if (!isEmpty(auth.user)) {
      onClose();
    }
  }, [auth.user, onClose]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title>Log In</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{ email: '', password: '' }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          if (!values.password) {
            errors.password = 'Required';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { email, password } = values;
          try {
            await dispatch(login({email, password}));
            setIsError(false);
          } catch {
            setIsError(true);
          }
          setSubmitting(false);
        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) =>
          <>
            <Modal.Body>
              {
                <>
                  { (isError && <Alert variant="danger">Login failed.</Alert>) || null }
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text>Email</InputGroup.Text>
                    <FormControl
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.email && touched.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email && touched.email && errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Password</InputGroup.Text>
                    <FormControl
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.password && touched.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password && touched.password && errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </>
              }
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Login
              </Button>
            </Modal.Footer>
          </>
        }
      </Formik>
    </Modal>
  );
}

export default LoginModal;
