import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Col, Container, Row, InputGroup, FormControl, Table } from 'react-bootstrap';

import debounce from '../utils/debounce';
import Meta from '../components/Meta';
import Header from '../components/Header';
import { searchUsers } from '../store/actions/users';
import PaginationComponent from '../components/PaginationComponent';

const ManageEmployees = () => {
  const pageTitle = 'Manage Employees';

  const [page, setPage] = useState(0);
  const [username, setUserName] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [isShowUserDetails, setIsShowUserDetails] = useState(false);

  const users = useSelector(state => state.users.users);
  const pagination = useSelector(state => state.users.pagination);

  const dispatch = useDispatch();

  const verify = useCallback(
    (name) => debounce(name => {
      dispatch(searchUsers({pageNo: page, q: name}))
    }, 200)(name),
    [page, dispatch]
  );

  useEffect(() => {
    dispatch(searchUsers({pageNo: page}));
  }, [page, dispatch]);

  useEffect(() => {
    verify(username);
  }, [username, verify]);

  const handleDisplayUserDetails = (userDetails) => {
    setIsShowUserDetails(true);
    setSelectedUser(userDetails);
  };

  const employees = users.map(employee =>
    <tr key={`employee-${employee.userId}`} onClick={() => handleDisplayUserDetails(employee)}>
      <td>{employee.firstName} {employee.lastName}</td>
      <td>{employee.email}</td>
      <td>{employee.title}</td>
    </tr>
  );

  if (isShowUserDetails) {
    return <Redirect to={{
      pathname: `/admin/manage-employees/${selectedUser.userId}`,
      state: { "selectedUser": selectedUser}
    }}/>;
  }

  return (
    <Container>
      <Meta title={pageTitle}/>
      <Header head={pageTitle} />
      <Row className="mb-5">
        <Col md="12">
          <div className="float-md-end">
            <InputGroup>
              <FormControl
                placeholder="Search employees"
                onChange={e => setUserName(e.target.value)}
                value={username}
              />
            </InputGroup>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Table bordered hover responsive>
            <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Title</th>
            </tr>
            </thead>
            <tbody>
              {employees}
            </tbody>
          </Table>
          <PaginationComponent
            updatePage={setPage}
            {...pagination}
          />
        </Col>
      </Row>
      <Row className="mb-5" />
    </Container>
  );
}

export default ManageEmployees;
