import {Container, Form, Button, Row} from 'react-bootstrap';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { updateBio } from '../../store/actions/users';

import './ProfileEdit.scss';
import Meta from '../../components/Meta';
import Header from '../../components/Header';

const BioEdit = () => {
  const pageTitle = 'Edit My Bio';

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [bio, setBio] = useState(auth?.user?.bio);

  const submitBio = async () => {
    setSubmitting(true);
    await dispatch(updateBio(bio));
    setSubmitting(false);
  };

  return (
    <Container className="profile-edit-container">
      <Meta title={pageTitle}/>
      <Header head={pageTitle} />
      <Form>
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            rows={10}
            onChange={e => setBio(e.target.value)}
            value={bio}
          />
        </Form.Group>
      </Form>
      <Button
        variant="primary"
        onClick={submitBio}
        disabled={submitting}
      >
        Submit
      </Button>
      <Row className="mb-5" />
      <Row className="mb-5" />
    </Container>
  )
}

export default BioEdit;
