import React, { useState, useEffect, useCallback } from 'react';
import { Image, Alert, Button, InputGroup, Form, FormControl, Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import 'react-toastify/dist/ReactToastify.css';

import Meta from '../../components/Meta'
import { login } from '../../store/actions/auth';

import './Home.scss';

function Home() {
  const pageTitle = 'Employee Portal';
  const [isError, setIsError] = useState(false);
  const [externalMessage, setExternalMessage] = useState('');

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);

  const handlePostMessage = useCallback((event) => {
    setExternalMessage(event.data);
  }, []);

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [handlePostMessage]);

  if (!isEmpty(auth.user)) {
    return auth.user.role === 'ADMIN'
      ? <Redirect to="/admin/dashboard" />
      : <Redirect to="/dashboard" />;
  }

  return (
    <Container fluid id="home">
      <Row>
        <Col md={12} className="text-center">
          <Meta title={pageTitle}/>
          <div className="home-jumbo">
            <h1>
              <Image src="/img/logo_white.svg" responsive />
              <br/>
              Employee Portal
            </h1>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5 mb-5">
        <Col md={6} className="login-form">
          {
            typeof externalMessage === 'string'
              ? <div dangerouslySetInnerHTML={{__html: externalMessage}}></div>
              : null
          }
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            if (!values.password) {
              errors.password = 'Required';
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { email, password } = values;
            try {
              await dispatch(login({email, password}));
              setIsError(false);
            } catch {
              setIsError(true);
            }
            setSubmitting(false);
          }}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) =>
              <>
                { (isError && <Alert variant="danger">Login failed.</Alert>) || null }
                <InputGroup className="mb-3" hasValidation>
                  <InputGroup.Text>Email</InputGroup.Text>
                  <FormControl
                    id="email"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.email && touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email && touched.email && errors.email}
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Password</InputGroup.Text>
                  <FormControl
                    id="password"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.password && touched.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password && touched.password && errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
                <Button
                  variant="primary"
                  className="float-end"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Login
                </Button>
              </>
            }
          </Formik>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
