import axios from './axios';

/**
 * API for searching paychecks, paginated
 *
 * @returns {Promise}
 */
const searchPaychecks = async ({userId, pageNo, pageSize, sortBy}) =>
  await axios.get(`/users/${userId}/paychecks`, {
    params: {
      pageNo,
      pageSize,
      sortBy,
    }
  });

const PaycheckService = {
  searchPaychecks,
};

export default PaycheckService;
