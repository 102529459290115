import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Table } from 'react-bootstrap';
import moment from 'moment';

import Meta from '../components/Meta';
import Header from '../components/Header';
import { searchPaychecks } from '../store/actions/paychecks';
import PaginationComponent from '../components/PaginationComponent';

const PayHistory = () => {
  const pageTitle = 'Pay History';

  const [page, setPage] = useState(0);

  const paychecks = useSelector(state => state.paychecks.paychecks);
  const pagination = useSelector(state => state.paychecks.pagination);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchPaychecks({pageNo: page}));
  }, [page, dispatch]);

  const payHistory = paychecks.map(payCheck =>
    <tr key={`paycheck-${payCheck.id}`}>
      <td>{moment(payCheck.periodStart).format('L')} to {moment(payCheck.periodEnd).format('L')}</td>
      <td>${payCheck.grossPay}</td>
    </tr>
  );

  return (
    <Container>
      <Meta title={pageTitle}/>
      <Header head={pageTitle} />
      <Row className="mt-3 mb-5">
        <Col>
          <Table bordered hover responsive>
            <thead>
            <tr>
              <th>Pay Period</th>
              <th>Gross Pay</th>
            </tr>
            </thead>
            <tbody>
              {payHistory}
            </tbody>
          </Table>
          <PaginationComponent
            updatePage={setPage}
            {...pagination}
          />
        </Col>
      </Row>
      <Row className="mb-5" />
    </Container>
  );

};

export default PayHistory;
