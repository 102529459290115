import React from 'react';
import { Card, Container } from 'react-bootstrap';

const DashboardCard = (props) => {
  const { label, children } = props;

  return (
    <Card>
      <Container>
        <Card.Body className="text-center mt-5 mb-5">
          <Card.Text className="mt-3 fw-bold fs-5">
            {children}
            {label}
          </Card.Text>
          <span className="fa-4x text-uppercase">{props.month}</span>
          <br/>
          <span className="fa fw-bolder fs-1">{props.day}</span>
        </Card.Body>
      </Container>
    </Card>
  );
}

export default DashboardCard;
