import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';

import Header from '../components/Header';
import Meta from '../components/Meta';
import DashboardCard from '../components/dashboard/DashboardCard';
import { months } from '../utils/utils';
import { getMessageOfTheDay } from '../store/actions/motd';

import './Dashboard.scss'

const Dashboard = () => {
  // page content
  const pageTitle = 'Welcome, employee';
  let now = dayjs();

  const message = useSelector(state => state.motd.message);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!message) {
      dispatch(getMessageOfTheDay());
    }
  }, [dispatch, message]);

  /*
    For Next Paycheck, the date of the latest paycheck plus two weeks (should be on a Friday).

    Friday day is 5.
   */
  const getNextPayCheckDate = () => {
    let day = now.day();
    let daysDiff = 0;

    if (day < 5) {
      daysDiff = 14 + (5 - day);
    } else if (day === 5) {
      daysDiff = 14;
    } else {
      daysDiff = 14 - (day % 5)
    }

    return dayjs().add(daysDiff, 'day');
  };

  let nextPayCheckDate = getNextPayCheckDate();

  function getRandomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  let futurePayCheckDate = dayjs().add(getRandomInRange(5 * 365, 6 * 365), 'day');

  return (
    <Container className="dashboard">
      <Meta title={pageTitle}/>
      <Header head={pageTitle} />
      <Row className="mb-5">
        <Col lg={4}>
          <DashboardCard
            label="Last Paycheck"
            month={months[now.month()]}
            day={now.date()}
          />
        </Col>
        <Col lg={4}>
          <DashboardCard
            label="Next Paycheck"
            month={months[nextPayCheckDate.month()]}
            day={nextPayCheckDate.date()}
          />
        </Col>
        <Col lg={4}>
          <DashboardCard
            label="Next Holiday"
            month={months[futurePayCheckDate.month()]}
            day={futurePayCheckDate.date()}
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <div className="announcement">
          <h1>ANNOUNCEMENT</h1>
          <span>{message}</span>
        </div>
      </Row>
      <Row className="mb-5" />
    </Container>
  );
};

export default Dashboard;
