import {LOGIN_START, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT} from '../actions/auth';
import {
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_ERROR,
  USER_BIO_UPDATE_SUCCESS,
  USER_BIO_UPDATE_ERROR
} from '../actions/users';

const initialAuthState = {
    user: {},
    error: false,
};

const authReducer = (auth = initialAuthState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                user: action.user,
                token: action.token,
                error: false
            };

        case LOGIN_ERROR:
            return {
                ...auth,
                error: true,
            };
        case LOGOUT:
            return initialAuthState;
        case USER_PROFILE_UPDATE_SUCCESS:
            return {
                ...auth,
                user: action.user,
                error: false,
            };
      case USER_BIO_UPDATE_SUCCESS:
          return {
            ...auth,
            user: {
              ...auth.user,
              bio: action.bio
            },
            error: false,
          };
      case USER_BIO_UPDATE_ERROR:
      case USER_PROFILE_UPDATE_ERROR:
            return {
                ...auth,
                error: true,
            };
        case LOGIN_START:
        default:
            return {
                ...auth,
                error: false
            };
    }
};

export default authReducer;
