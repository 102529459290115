import './PasswordChange.scss';
import { useDispatch, useSelector } from 'react-redux';
import {Container, InputGroup, Form, FormControl, Button, Row} from 'react-bootstrap';
import React from 'react';
import { updateProfile } from '../../store/actions/users';
import { Formik } from 'formik';
import Meta from '../../components/Meta';
import Header from '../../components/Header';

const PasswordChange = () => {
  const pageTitle = 'Change My Password';

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const onSubmitHandler = async (values, { setSubmitting }) => {
    const { password } = values;
    const userId = auth.user.userId;

    setSubmitting(true);
    await dispatch(updateProfile({userId, password}));
    setSubmitting(false);
  };

  return (
    <Container className='password-change-container'>
      <Meta title={pageTitle}/>
      <Header head={pageTitle} />
      <Formik
        initialValues={{
          password: "",
          confirmPassword: ""
        }}
        validate={values => {
          const errors = {};
          if (!values.password) {
            errors.password = 'Required';
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = 'Required';
          }
          if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Passwords don't match";
          }
          return errors;
        }}
        onSubmit={onSubmitHandler}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleReset,
            handleSubmit,
            isSubmitting,
          }) => <>
          <InputGroup className="mb-3" hasValidation>
            <InputGroup.Text>New Password</InputGroup.Text>
            <FormControl
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={errors.password && touched.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password && touched.password && errors.password}
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-3" hasValidation>
            <InputGroup.Text>Confirm Password</InputGroup.Text>
            <FormControl
              type="password"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={errors.confirmPassword && touched.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
            </Form.Control.Feedback>
          </InputGroup>
          <div className="profile-btns">
            <Button
              variant="secondary"
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </>
        }
      </Formik>
      <Row className="mb-5" />
      <Row className="mb-5" />
    </Container>
  )
}

export default PasswordChange;
