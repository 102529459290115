import { MOTD_GET_SUCCESS } from '../actions/motd';
import { LOGOUT } from '../actions/auth';

const initialMotdState = {
  message: '',
};

const motdReducer = (motd = initialMotdState, action) => {
  switch (action.type) {
    case MOTD_GET_SUCCESS:
      return {
        message: action.message
      };

    case LOGOUT:
      return initialMotdState;

    default:
      return {
        ...motd
      };
  }
};

export default motdReducer;
