import { Container } from 'react-bootstrap';

const NotFound = () => {
  const rfc = `Fielding & Reschke           Standards Track                   [Page 58]


RFC 7231             HTTP/1.1 Semantics and Content            June 2014

[...]

6.5.4.  404 Not Found

   The 404 (Not Found) status code indicates that the origin server did
   not find a current representation for the target resource or is not
   willing to disclose that one exists.  A 404 status code does not
   indicate whether this lack of representation is temporary or
   permanent; the 410 (Gone) status code is preferred over 404 if the
   origin server knows, presumably through some configurable means, that
   the condition is likely to be permanent.

   A 404 response is cacheable by default; i.e., unless otherwise
   indicated by the method definition or explicit cache controls (see
   Section 4.2.2 of [RFC7234]).

[...]`;

  return (
    <Container fluid='md' className='mt-5'>
      <h1>404</h1>
      <div className='mt-5'>
        <pre>{rfc}</pre>
      </div>
    </Container>
  );
}

export default NotFound;
