import PaycheckService from '../../services/PaycheckService';
import withAuthCheck from '../actions/utils/withAuthCheck';

export const PAYCHECK_SEARCH_START = 'PAYCHECK_SEARCH_START';
export const PAYCHECK_SEARCH_SUCCESS = 'PAYCHECK_SEARCH_SUCCESS';
export const PAYCHECK_SEARCH_ERROR = 'PAYCHECK_SEARCH_ERROR';

export const searchPaychecks = ({pageNo}) =>
  async (dispatch, getState) => {
    const { auth } = getState();

    const userId = auth.user.userId;

    dispatch({
      type: PAYCHECK_SEARCH_START
    });

    try {
      const response = await withAuthCheck(PaycheckService.searchPaychecks, dispatch)({
        userId, pageNo, pageSize: 20
      });
      const { content, pageable, first, last, totalPages } = response.data;
      dispatch({
        type: PAYCHECK_SEARCH_SUCCESS,
        paychecks: content,
        pagination: {
          pageNumber: pageable.pageNumber,
          totalPages,
          firstPage: first,
          lastPage: last
        }
      });
    } catch (ex) {
      dispatch({
        type: PAYCHECK_SEARCH_ERROR
      });
    }
  };
