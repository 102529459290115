import axios from './axios';
import moment from "moment";

/**
 * Get user details.
 *
 * @returns {Promise}
 */
const getUser = async ({userId}) =>
  await axios.get(`/users/${userId}`);

/**
 * Update profile API.
 *
 * @returns {Promise}
 */
const updateProfile = async ({userDetail}) => {
  let userId = userDetail.userId;
  delete userDetail.userId;

  let formData = new FormData();
  if ('profilePic' in userDetail) {
    formData.append("profilePic", userDetail.profilePic);
    delete userDetail.profilePic;
  }

  formData.append("fields",
    new Blob([JSON.stringify(userDetail)], { type: 'application/json' }),
    'fields.json');

  return await axios.patch(`/users/${userId}`, formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
};

/**
 * Update user bio API.
 *
 * @returns {Promise}
 */
const updateBio = async ({bio}) => {
  return await axios.post(`/user/bio`, bio,
    {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
};

/**
 * Users API for searching users, paginated
 *
 * @returns {Promise}
 */
const searchUsers = async ({pageNo, pageSize, sortBy, q}) =>
  await axios.get('/users', {
    params: {
      pageNo,
      pageSize,
      sortBy,
      q
    }
  });


/**
 * Users API for searching users, paginated
 *
 * @returns {Promise}
 */
const deleteUser = async (userId) =>
  await axios.delete(`/users/${userId}`);


/**
 * Users API for searching users, paginated
 *
 * @returns {Promise}
 */
const updateUser = async ({userDetail}) => {
  let userId = userDetail.userId;

  let formData = new FormData();

  if ('profilePic' in userDetail) {
    formData.append("profilePic", userDetail.profilePic);
  }
  formData.append("email", userDetail.email);
  formData.append("gender", userDetail.gender);
  formData.append("dateOfBirth", moment(userDetail.dateOfBirth).format("DD/MM/YYYY"));
  formData.append("salary", userDetail.salary);
  formData.append("status", userDetail.status);
  formData.append("title", userDetail.title);
  formData.append("role", userDetail.role);
  formData.append("firstName", userDetail.firstName);
  formData.append("lastName", userDetail.lastName);

  return await axios.put(`/users/${userId}`, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
};


const UserService = {
  updateProfile,
  updateBio,
  updateUser,
  searchUsers,
  deleteUser,
  getUser
};

export default UserService;
