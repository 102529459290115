import { USER_SEARCH_START, USER_SEARCH_SUCCESS, USER_SEARCH_ERROR } from '../actions/users';
import { LOGOUT } from '../actions/auth';

const initialUsersState = {
  users: [],
  pagination: {
    pageNumber: 0,
    totalPages: 1,
    firstPage: true,
    lastPage: true
  }
};

const usersReducer = (users = initialUsersState, action) => {
  switch (action.type) {
    case USER_SEARCH_SUCCESS:
      return {
        users: action.users,
        pagination: action.pagination
      };

    case USER_SEARCH_ERROR:
      return {
        ...users
      };

    case LOGOUT:
      return initialUsersState;
    case USER_SEARCH_START:
    default:
      return {
        ...users
      };
  }
};

export default usersReducer;
