import axios from './axios';

/**
 * Login API
 *
 * @param email
 * @param password
 * @returns {Promise}
 */
const login = async ({email, password}) =>
  await axios.post('/login', {
    email,
    password
  });

const tokenLogin = async () =>
  await axios.get('/login');

const logout = async () =>
  await axios.get('/signout');

const AuthService = {
  login,
  tokenLogin,
  logout,
};

export default AuthService;
