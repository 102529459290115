import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import isEmpty from 'lodash/isEmpty';

import LoginModal from '../../components/login/LoginModal';
import { logout } from '../../store/actions/auth';
import './Menu.scss';

const Menu = () => {
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);

  const toggleShowLoginModal = () => setIsShowLoginModal(!isShowLoginModal);
  const auth = useSelector(state => state.auth);

  const ssoRedirectUri = `/api/login/sso?redirect_uri=${window.globalConfig.MJ_APP_URL}/sso`;
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout);
  };

  let menu = (
    <Navbar.Collapse className="justify-content-end">
      <button
        type='button'
        className='btn btn-primary me-2'
        onClick={toggleShowLoginModal}
      >
        Login
      </button>
    </Navbar.Collapse>
  );

  if (!isEmpty(auth.user)) {
    const profileImgSrc = auth.user.profilePic
      ? "data:image/png;charset=utf-8;base64, " + auth.user.profilePic
      : `${process.env.PUBLIC_URL}/img/default-avatar.svg`;

    menu = (
      <>
        <Navbar.Collapse>
          <a className='nav-link link-light' href={ssoRedirectUri}>Launch MailJay</a>
          <LinkContainer to='/directory'>
            <Nav.Link className='nav-link link-light'>Company Directory</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/pay-history'>
            <Nav.Link className='nav-link link-light'>Pay History</Nav.Link>
          </LinkContainer>
          {
            auth.user.role === 'ADMIN'
            ? (
              <LinkContainer to='/admin/manage-employees'>
                <Nav.Link className='nav-link link-light'>Manage Employees</Nav.Link>
              </LinkContainer>
            ) : null
        }
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          {
            auth.user.profilePic
              ? (
                <div className="profile-pic-image">
                  <img alt="FUN PERSONALIZED FLAIR FOR OUR HUMAN RESOURCES" src={profileImgSrc} />
                </div>
              ) : null
          }
          <NavDropdown title={<span>{auth.user.firstName}</span>}>
            <LinkContainer to='/profile-edit'>
              <NavDropdown.Item>Profile Edit</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to='/bio-edit'>
              <NavDropdown.Item>Bio Edit</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to='/password-change'>
              <NavDropdown.Item>Change Password</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </>
    );
  }

  return (
    <>
      <Navbar bg="primary" className="nav-menu">
        <Navbar.Brand>
          <LinkContainer to='/'>
            <Nav.Link className="nav-link link-light menu-logo">
              <Image src="/img/logo_white.svg" />
              EMPLOYEES ONLY
            </Nav.Link>
          </LinkContainer>
        </Navbar.Brand>
        { menu }
      </Navbar>
      <LoginModal show={isShowLoginModal} onClose={() => setIsShowLoginModal(false)} />
    </>
  );
};

export default Menu;
