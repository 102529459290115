import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';

const ProtectedRoute = (props) => {
  const user = useSelector(state => state.auth?.user);
  const role = user?.role;

  if (props.role !== role && role !== 'ADMIN') {
    return <Redirect to="/" />;
  }

  const routeProps = {...props};
  delete routeProps.role;

  return <Route {...routeProps} />
};

export default ProtectedRoute;
