import axios from './axios';

/**
 * Get message of the day.
 *
 * @returns {Promise}
 */
const getMessageOfTheDay = async () =>
  await axios.get('/motd');


const MotdService = {
  getMessageOfTheDay,
};

export default MotdService;
