import React, { useState, useEffect } from 'react';
import { Col, Container, Row, FormControl, Button, Form, Figure, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUser } from '../store/actions/users';
import moment from 'moment';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import UserService from '../services/UserService';
import withAuthCheck from '../store/actions/utils/withAuthCheck';
import './EmployeeDetail.scss';
import Meta from '../components/Meta';
import Header from '../components/Header';

const EmployeeDetails = (props) => {
  const userDetails = props.location.state.selectedUser;
  const pageTitle = `${userDetails.firstName} ${userDetails.lastName}`;

  const [preview, setPreview] = useState();
  const [selectedFile, setSelectedFile] = useState(userDetails.profilePic);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return
    }

    let objectUrl;
    if (typeof selectedFile === "string") {
      setPreview("data:image/png;charset=utf-8;base64, " + selectedFile);
    } else {
      objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile])

  const onFileChange = (e, setFieldValue) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return;
    }
    setSelectedFile(e.target.files[0]);
    setFieldValue('profilePic', e.target.files[0]);
  };

  const validateForm = values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.gender) {
      errors.gender = 'Gender is required';
    }

    if (!values.dateOfBirth) {
      errors.dateOfBirth = 'Date Of Birth is required';
    } else if (values.dateOfBirth > moment().format('YYYY-MM-DD')) {
      errors.dateOfBirth = 'Date Of Birth should be in past';
    }

    if (!values.title) {
      errors.title = 'Title is required';
    }

    if (!values.salary) {
      errors.salary = 'Salary is required';
    }

    if (!values.status) {
      errors.status = 'Status is required';
    }

    if (values.profilePic?.size > 50 * 1024) {
      errors.profilePic = 'Profile pic size cannot exceed 50 KB';
    }

    return errors;
  };

  const renderBasicProfileInfo = (values, errors, touched, handleChange, handleBlur) => {
    return (
      <div className="p-3">
        <h3>Basic Profile Information</h3>
        <Row className="mt-2">
          <Col md={12}>
            <InputGroup className="mb-3" hasValidation>
              <InputGroup.Text>Email</InputGroup.Text>
              <FormControl
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.email && touched.email}
              />
              <Form.Control.Feedback type="invalid">
                {touched.email && errors.email}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            <InputGroup className="mb-3" hasValidation>
              <InputGroup.Text>Gender</InputGroup.Text>
              <FormControl
                type="text"
                name="gender"
                value={values.gender}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.gender && touched.gender}
              />
              <Form.Control.Feedback type="invalid">
                {touched.gender && errors.gender}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            <InputGroup hasValidation>
              <InputGroup.Text>Date of Birth</InputGroup.Text>
              <FormControl
                type="date"
                name="dateOfBirth"
                value={moment(values.dateOfBirth).format('YYYY-MM-DD')}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.dateOfBirth && touched.dateOfBirth}
              />
              <Form.Control.Feedback type="invalid">
                {touched.dateOfBirth && errors.dateOfBirth}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
      </div>
    );
  }

  const renderEmploymentInfo = (values, errors, touched, handleChange, handleBlur) => {
    return (
      <div className="p-3">
        <h3>Employment Information</h3>
        <Row className="mt-1">
          <Col md={12}>
            <InputGroup className="mb-3" hasValidation>
              <InputGroup.Text>Title</InputGroup.Text>
              <FormControl
                type="text"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.title && touched.title}
              />
              <Form.Control.Feedback type="invalid">
                {touched.title && errors.title}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={12}>
            <InputGroup className="mb-3" hasValidation>
              <InputGroup.Text>Status</InputGroup.Text>
              <FormControl
                type="text"
                name="status"
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.status && touched.status}
              />
              <Form.Control.Feedback type="invalid">
                {touched.status && errors.status}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            <InputGroup hasValidation>
              <InputGroup.Text>Salary</InputGroup.Text>
              <FormControl
                type="number"
                name="salary"
                value={values.salary}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.salary && touched.salary}
              />
              <Form.Control.Feedback type="invalid">
                {touched.salary && errors.salary}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
      </div>
    );
  }

  const renderProfilePic = (touched, errors, handleChange, setFieldValue) => {
    return (
      <>
        <h5>Profile Picture</h5>
        <Form.Group controlId="profilePic">
          {
            selectedFile && <Figure>
              <Figure.Image
                width={150}
                height={150}
                alt="150x150"
                src={ preview }
              />
            </Figure>
          }
        </Form.Group>
        <Form.Group controlId="profilePic">
          <FormControl
            type="file"
            isInvalid={errors.profilePic && touched.profilePic}
            onChange={e => onFileChange(e, setFieldValue)}
          />
          <Form.Control.Feedback type="invalid">
            {touched.profilePic && errors.profilePic}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  }

  const renderDetails = (values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue) => {
    return (
      <div className="outer-container">
        <div>
          <Row>
            <Col xs="6">
              <div>
                {renderBasicProfileInfo(values, errors, touched, handleChange, handleBlur)}
                {renderEmploymentInfo(values, errors, touched, handleChange, handleBlur)}
              </div>
            </Col>
            <Col xs="6">
              <div className="p-4 profile-pic">
                {renderProfilePic(touched, errors, handleChange, setFieldValue)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const onTerminateEmployment = async () => {
    const userId = userDetails.userId;
    try {
      await withAuthCheck(UserService.deleteUser, dispatch)(userId);
      history.push(`/admin/manage-employees`);
      toast.success('Employee: Terminated.');
    } catch (ex) {
      let errorMsg = ex.response.data.message;
      toast.error(errorMsg);
    }
  };

  const onCancel = () => {
    history.push(`/admin/manage-employees`);
  };

  const onSubmitHandler = async (userDetail) => {
      await dispatch(updateUser(userDetail));
      history.push(`/admin/manage-employees`);
  };

  return (
    <Container>
      <Meta title={pageTitle}/>
      <Formik
        initialValues={userDetails}
        validate={validateForm}
        onSubmit={async (values, { setSubmitting }) => {onSubmitHandler({...values,});
        setSubmitting(false);
      }}>
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
            <>
              <Header head={pageTitle} />
              {renderDetails(values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue)}
              <div className="p-3 float-end">
                <Button variant="secondary mx-1" onClick={onCancel}>Cancel</Button>
                <Button variant="primary mx-1" onClick={handleSubmit}>Save</Button>
              </div>
            </>
          )
        }
      </Formik>
      <Button className="m-4 w-25" variant="danger" onClick={onTerminateEmployment}>Terminate Employment</Button>
      <Row className="mb-5" />
    </Container>
  );
};

export default EmployeeDetails;
