import Cookies from 'js-cookie'
import AuthService from '../../services/AuthService';
import withAuthCheck from './utils/withAuthCheck';

import { toast } from 'react-toastify';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const login = ({email, password}) =>
  async (dispatch) => {
    dispatch({
      type: LOGIN_START
    });
    try {
      const response = await withAuthCheck(AuthService.login, dispatch)({email, password});
      const { user, token } = response.data;
      dispatch({
        type: LOGIN_SUCCESS,
        user,
        token,
      });
    } catch (ex) {
      dispatch({
        type: LOGIN_ERROR,
        errorMsg: ex.response.data.message
      });
      toast.error('Login failed');
    }
  };

export const tokenLogin = () =>
  async (dispatch) => {
    dispatch({
      type: LOGIN_START
    });
    try {
      if (!Cookies.get('jwt')) {
        Cookies.set('jwt', 'undefined');
      }
      const response = await withAuthCheck(AuthService.tokenLogin, dispatch)();
      const { user, token } = response.data;
      dispatch({
        type: LOGIN_SUCCESS,
        user,
        token,
      });
    } catch (ex) {
      dispatch({
        type: LOGIN_ERROR
      });
    }
  };

export const logout = (dispatch) => {
  Cookies.set('jwt', 'undefined');
  AuthService.logout();
  dispatch({
    type: LOGOUT
  });
};
