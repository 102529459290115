import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router} from 'react-router-dom';

import Menu from '../components/menu/Menu';
import Footer from '../components/Footer';

const Layout = ({ children, auth }) => {
  return (
    <Router>
      <Menu />
      <main>{children}</main>
      <Footer />
    </Router>
  );
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Layout);
