import React from 'react';
import { Pagination } from 'react-bootstrap';
import _ from 'lodash';

const paginationItemPadsize = 6;

function PaginationComponent(props) {
  const { pageNumber, totalPages, updatePage } = props;

  if (pageNumber === 0 && totalPages <= 1) {
    return <></>;
  }

  let leftRange = 2;
  let rightRange = totalPages;

  if (pageNumber - paginationItemPadsize > 1) {
    leftRange = pageNumber - paginationItemPadsize + 1;
  }
  if (pageNumber + paginationItemPadsize < totalPages) {
    rightRange = pageNumber + paginationItemPadsize + 2;
  }

  const rightLeftover = paginationItemPadsize - (totalPages - pageNumber - 2);
  if (rightLeftover > 0) {
    leftRange =
      leftRange - rightLeftover >= 2
        ? leftRange - rightLeftover
        : 2;
  }

  const leftLeftover = paginationItemPadsize - pageNumber;
  if (leftLeftover > 0) {
    rightRange =
      rightRange + leftLeftover < totalPages
        ? rightRange + leftLeftover
        : totalPages;
  }

  const range = _.range(leftRange, rightRange);

  const paginationItems = range
    .map((page, idx) => {
      const currentPageIdx = range.indexOf(pageNumber);
      const className =
        idx === currentPageIdx + 1
          ? ''
          : 'd-xxs-none d-xs-none d-sm-none d-md-block';

      return (
        <Pagination.Item
          key={`item-${page}`}
          onClick={() => updatePage(page-1)}
          active={pageNumber+1 === page}
          activeLabel=""
          className={className}
        >
          {page}
        </Pagination.Item>
      );
    });

  return (
    <Pagination className="justify-content-center">
      <Pagination.Item
        onClick={() => updatePage(pageNumber === 0 ? 0 : pageNumber-1)}
      >
        &lsaquo;
      </Pagination.Item>
      <Pagination.Item
        onClick={() => updatePage(0)}
        active={pageNumber === 0}
        activeLabel=""
      >
        {1}
      </Pagination.Item>
      <Pagination.Item
        className={{
          'd-md-none': (range.length && range[0] === 2) || range.length <= 2
        }}
      >
        &hellip;
      </Pagination.Item>
      {paginationItems}
      <Pagination.Item
        className={{
          'd-md-none': (range.length && range[range.length-1] === totalPages - 1) || range.length <= 2
        }}
      >
        &hellip;
      </Pagination.Item>
      <Pagination.Item
        onClick={() => updatePage(totalPages-1)}
        active={pageNumber === totalPages-1}
        activeLabel=""
      >
        {totalPages}
      </Pagination.Item>
      <Pagination.Item
        onClick={() => updatePage(pageNumber === totalPages-1 ? totalPages-1 : pageNumber+1)}
      >
        &rsaquo;
      </Pagination.Item>
    </Pagination>
  );
}

export default PaginationComponent;
