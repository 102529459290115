import UserService from '../../services/UserService';
import withAuthCheck from '../actions/utils/withAuthCheck';

import { toast } from 'react-toastify';

export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR';
export const USER_BIO_UPDATE_SUCCESS = 'USER_BIO_UPDATE_SUCCESS';
export const USER_BIO_UPDATE_ERROR = 'USER_BIO_UPDATE_ERROR';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';
export const USER_SEARCH_START = 'USER_SEARCH_START';
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS';
export const USER_SEARCH_ERROR = 'USER_SEARCH_ERROR';

export const getUser = ({userId}) =>
  async (dispatch) => {
    try {
      const response = await withAuthCheck(UserService.getUser, dispatch)({
        userId
      });
      const user = response.data;

      dispatch({
        type: GET_USER_DETAILS_SUCCESS,
        user
      });
    } catch (ex) {
      dispatch({
        type: GET_USER_DETAILS_ERROR
      });
    }
  };

export const updateProfile = (userDetail) =>
  async (dispatch) => {
    try {
      const response = await withAuthCheck(UserService.updateProfile, dispatch)({
        userDetail
      });
      const user = response.data;

      dispatch({
        type: USER_PROFILE_UPDATE_SUCCESS,
        user
      });
      toast.success('Profile updated successfully');
    } catch (ex) {
      dispatch({
        type: USER_PROFILE_UPDATE_ERROR,
        message: ex.response.data.message
      });
      toast.error(ex.response.data.message);
    }
  };

export const updateBio = (bio) =>
  async (dispatch) => {
    try {
      await withAuthCheck(UserService.updateBio, dispatch)({
        bio
      });

      dispatch({
        type: USER_BIO_UPDATE_SUCCESS,
        bio
      });
      toast.success('Bio updated successfully');
    } catch (ex) {
      dispatch({
        type: USER_BIO_UPDATE_ERROR,
        message: ex.response.data.message
      });
      toast.error(ex.response.data.message);
    }
  };

export const searchUsers = ({pageNo, sortBy = 'email', q}) =>
  async (dispatch) => {
    dispatch({
      type: USER_SEARCH_START
    });

    try {
      const response = await withAuthCheck(UserService.searchUsers, dispatch)({
        pageNo, pageSize: 20, sortBy, q
      });
      const { content, pageable, first, last, totalPages } = response.data;
      dispatch({
        type: USER_SEARCH_SUCCESS,
        users: content,
        pagination: {
          pageNumber: pageable.pageNumber,
          totalPages,
          firstPage: first,
          lastPage: last
        }
      });
    } catch (ex) {
      dispatch({
        type: USER_SEARCH_ERROR
      });
    }
  };

export const updateUser = (userDetail) => async (dispatch) => {
    // Send the user profile pic only if the field is changed
    if (typeof userDetail.profilePic === 'string') {
      delete userDetail.profilePic;
    }

    try {
      await withAuthCheck(UserService.updateProfile, dispatch)({
        userDetail
      });
      toast.success('Employee updated successfully');

    } catch (ex) {
      toast.error('Error updating employee');
    }
  };
