import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Container, Row, InputGroup, FormControl } from 'react-bootstrap';

import debounce from '../utils/debounce';
import Meta from '../components/Meta';
import Header from '../components/Header';
import { searchUsers } from '../store/actions/users';
import PaginationComponent from '../components/PaginationComponent';

import './CompanyDirectory.scss';

const CompanyDirectory = () => {
  const pageTitle = 'Employee Directory';

  const [page, setPage] = useState(0);
  const [username, setUserName] = useState('');

  const users = useSelector(state => state.users.users);
  const pagination = useSelector(state => state.users.pagination);

  const dispatch = useDispatch();

  const verify = useCallback(
    (name) => debounce(name => {
      dispatch(searchUsers({pageNo: page, q: name}))
    }, 200)(name),
    [page, dispatch]
  );

  useEffect(() => {
    dispatch(searchUsers({pageNo: page}));
  }, [page, dispatch]);

  useEffect(() => {
    verify(username);
  }, [username, verify]);

  const employees = users.map(employee =>
    <Col md={6}>
      <Card>
        <Card.Body>
          <Card.Title>{employee.firstName} {employee.lastName}</Card.Title>
          <Card.Text>
            Contact: {employee.email}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Text>
            {employee.bio}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <Container className="company-directory">
      <Meta title={pageTitle}/>
      <Header head={pageTitle} />
      <Row>
        <Col md="12">
          <div className="float-md-end">
            <InputGroup>
              <FormControl
                placeholder="Search employees"
                onChange={e => setUserName(e.target.value)}
                value={username}
              />
            </InputGroup>
          </div>
        </Col>
      </Row>
      <Row className="mt-3 mb-5">
        {employees}
        <PaginationComponent
          updatePage={setPage}
          {...pagination}
        />
      </Row>
      <Row className="mb-5" />
    </Container>
  );
};

export default CompanyDirectory;
