import { PAYCHECK_SEARCH_START, PAYCHECK_SEARCH_SUCCESS, PAYCHECK_SEARCH_ERROR } from '../actions/paychecks';
import { LOGOUT } from '../actions/auth';

const initialPaychecksState = {
  paychecks: [],
  pagination: {
    pageNumber: 0,
    totalPages: 1,
    firstPage: true,
    lastPage: true
  }
};

const paychecksReducer = (paychecks = initialPaychecksState, action) => {
  switch (action.type) {
    case PAYCHECK_SEARCH_SUCCESS:
      return {
        paychecks: action.paychecks,
        pagination: action.pagination
      };

    case PAYCHECK_SEARCH_ERROR:
      return {
        ...paychecks
      };

    case LOGOUT:
      return initialPaychecksState;
    case PAYCHECK_SEARCH_START:
    default:
      return {
        ...paychecks
      };
  }
};

export default paychecksReducer;
