import React, { useState } from 'react';

import './Footer.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  const [leftEye, setLeftEye] = useState(false);
  const [rightEye, setRightEye] = useState(false);

  return (
    <footer className='text-center text-capitalize'>
      <div className='footer'>
        <span>
          Copyright MailJay Email Marketing Tools &copy; {year}
        </span>
      </div>
      <div className={`eyeballs${(leftEye && rightEye) ? ' ouch' : ''}`}>
        <div
          onClick={() => setLeftEye(true)}
          className={`eye${leftEye ? ' ouch' : ''}`}
        />
        <div
          onClick={() => setRightEye(true)}
          className={`eye${rightEye ? ' ouch' : ''}`}
        />
      </div>
    </footer>
  );
};

export default Footer;
